import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import i18n from "@/plugins/i18n";
import ajaxCalls from "@/plugins/ajaxCalls";

import VueMdijs from "vue-mdijs";
import {
  mdiImageOff,
  mdiCloseCircle,
  mdiChevronDown,
  mdiChevronUp,
  mdiChevronDoubleRight,
  mdiCheckCircleOutline
} from "@mdi/js";
import EnvProvider from "@tt/vue-environment/EnvProvider";
Vue.config.productionTip = false;

const ajaxCallsPlugin = {
  install () {
    Vue.ajaxCalls = ajaxCalls
    Vue.prototype.$ajaxCalls = ajaxCalls
  }
}

Vue.use(ajaxCallsPlugin)

VueMdijs.add({ mdiImageOff, mdiCloseCircle, mdiChevronDown, mdiChevronUp, mdiChevronDoubleRight, mdiCheckCircleOutline });
Vue.use(VueMdijs);

Vue.mixin({
  data: function() {
    return {
      logoUrl: EnvProvider.value("LOGO_URL"),
      headerColor: EnvProvider.value("HEADER_COLOR"),
      contentColor: EnvProvider.value("CONTENT_COLOR"),
    }
  }
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
