import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@/plugins/i18n";
import EnvProvider from "@tt/vue-environment";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/Login"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/products",
    name: "products",
    menuTitle: i18n.t("menu.title"),
    component: () => import("@/views/Products.vue"),
    meta: {
      requiresAuth: (EnvProvider.value("MUST_LOGIN") === "true"),
    }
  },
  {
    path: "/locations",
    name: "locations",
    menuTitle: i18n.t("locations.title"),
    component: () => import("@/views/Locations"),
    meta: {
      requiresAuth: true,
   }
  },
  {
    path: "/product/:strategy",
    name: "product",
    menuTitle: i18n.t("product.title"),
    component: () => import("@/views/Product"),
    meta: {
      requiresAuth: true,
   }
  },
  {
    path: "/menu",
    name: "menu",
    menuTitle: i18n.t("menu.title"),
    component: () => import("@/views/Menu"),
    meta: {
      requiresAuth: true,
   }
  },
  {
    path: "/strategy",
    name: "strategy",
    menuTitle: i18n.tc("strategy.title", 1),
    component: () => import("@/views/Strategy"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/crates",
    name: "crates",
    menuTitle: i18n.tc("crate.title", 1),
    component: () => import("@/views/CrateMenu.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/crate/:strategy",
    name: "crateOpen",
    menuTitle: i18n.tc("crate.title", 1),
    component: () => import("@/views/CrateOpen.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/crates/fill",
    name: "crateFill",
    menuTitle: i18n.tc("crate.fill", 2),
    component: () => import("@/views/CrateFill.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/crates/close",
    name: "crateClose",
    menuTitle: i18n.tc("crate.title", 2),
    component: () => import("@/views/CrateClose"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/loop/:sku",
    name: "Loop",
    menuTitle: i18n.t("loop.title"),
    component: () => import("@/views/Loop"),
    meta: {
      requiresAuth: (EnvProvider.value("MUST_LOGIN") === "true"),
   }
  },
  {
    path: "/order-menu",
    name: "orderMenu",
    menuTitle: i18n.t("Order.title"),
    component: () => import("@/views/OrderMenu"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/announce-order",
    name: "AnnounceOrder",
    menuTitle: i18n.t("Announced.title"),
    component: () => import("@/views/AnnounceOrder"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/pickup-order",
    name: "PickUpOrder",
    menuTitle: i18n.t("Announced.title"),
    component: () => import("@/views/PickUpOrder"),
    meta: {
      requiresAuth: true,
    }
  }
];

// create router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const loggedIn = window.sessionStorage.getItem('authenticated');
  const isAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (isAuth === true && loggedIn !== "true") {
    return next({ name: 'login' });
  }
  next();
});

export default router;
