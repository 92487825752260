import axios from "axios";
import EnvProvider from "@tt/vue-environment/EnvProvider";

const locationList = {
  namespaced: true,

  state: {
    items: [],
    sortByField: "name",
    sortDesc: false,
    search: "",

  },
  mutations: {
    SET_ITEMS(state, data) {
      state.items = data;
    },
  },
  actions: {
    fetchItems(context, refresh = true) {
      if (refresh || context.state.items.length === 0) {

        axios({
          method: "get",
          url:
              EnvProvider.value("STORE_ENTITY") +
              "locations" +
              "?page=1" +
              "&itemsPerPage=9999"
          ,
          headers: {
            Accept: "application/json",
            'x-entity-user': window.sessionStorage.getItem("credentials") ?? EnvProvider.value("GUEST_USER")+":"+EnvProvider.value("GUEST_PASSWORD"),
            'x-entity-user-barcode': window.sessionStorage.getItem("barcode")
          }
        }).then(
            (response) => {
              context.commit("SET_ITEMS", response.data);
            },
            err => {
              console.log(err);
            }
        );

      }
    }
  },
  getters: {
    locationByCode: (state) => (code) => {
      return state.items.find((item) => item.code === code)
    }
  }
}

export default locationList;
