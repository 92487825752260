<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page">
        <div class="header" :style="{'background-color':'#' + headerColor}">
          <div class="customer-logo">
            <img :src="logoUrl" alt="" />
          </div>
          <div class="logo" @click="toMenu">
            <img src="../public/img/sprite.svg#logo" alt="" />
          </div>
          <div class="nowifi" :class="{ 'active': wifi === false }">
            <img src="img/sprite.svg#wifi" alt="">
          </div>
        </div>
        <router-view />
      </div>
      <Development v-if="debug === '1'" />
      <InActivity />
    </div>
  </div>
</template>

<script>
import EnvProvider from "@tt/vue-environment/EnvProvider";
import Development from "@/components/Development";
import InActivity from "@/components/InActivity";
import packageJson from "../package.json";
import { mapActions } from "vuex";

export default {
  name: "App",
  components: { Development, InActivity },
  data: () => ({
    debug: EnvProvider.value("STORE_DEBUG"),
    storeAssistantUrl: EnvProvider.value("STORE_ASSISTANT_URL"),
    storeEntityUrl: EnvProvider.value("STORE_ENTITY"),
    storeAllocationUrl: EnvProvider.value("STORE_ALLOCATION"),
    storeOrderUrl: EnvProvider.value("STORE_ORDER"),
    barcodescannerFix: EnvProvider.value("BARCODESCANNER_FIX"),
    authenticated: true,
    wifi: true,
    version: packageJson.version
  }),
  mounted(){
    if (window.sessionStorage.getItem('authenticated') !== "true") {
      //this.$router.replace({ name: "login" });
    } else {
      this.authenticated = true;
    }
    this.loadConfig();
    return setInterval(()=> (window.navigator.onLine) ? this.wifi = true : this.wifi = false, 1000);
  },
  watch:{
    authenticated: function(newValue, oldValue){
      if(newValue === false && oldValue === true){
        this.$router.replace({ name: "login" });
      }
    }
  },
  methods: {
    ...mapActions("config", ["loadConfig"]),
    toMenu() {
      if (this.authenticated && window.sessionStorage.getItem("location") !== null) {
        return this.$router.push({ name: "menu"});
      }

      return this.$router.push({ name: "locations" });
    }
  }
};
</script>
