import Vue from "vue";
import Vuex from "vuex";
import config from "@/store/modules/config";
import products from "@/store/modules/products";
import announceOrder from "@/store/modules/announceOrder";
import pickUpOrder from "@/store/modules/pickUpOrder";
import strategy from "@/store/modules/strategy";
import locationsList from "@/store/modules/locationsList";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    config,
    products,
    strategy,
    announceOrder,
    pickUpOrder,
    locationsList
  }
});

export default store;
