import axios from "axios";
import EnvProvider from "@tt/vue-environment/EnvProvider";
import stock from "@/store/modules/products/stock.js"

const products = {
  namespaced: true,
  modules: {
    stock
  },
  state: {
    loading: false,
    error: false,
    items: [],
    totalItems: 0,
    itemsPerPage: 10,
    itemsPage: 1,
    sortByField: "id",
    sortDesc: true,
    search: "",
    sku: null,
    connection: true,
    showItems: false
  },
  mutations: {
    SET_SEARCH(state, search) {
      state.search = search;
    },
    SET_CONNECTION(state, connection) {
      state.connection = connection;
    },
    SET_ITEMS(state, data) {
      state.items = data;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_SKU(state, sku) {
      state.sku = sku;
    }
  },
  actions: {
    setSku(context, sku) {
      context.commit("SET_SKU", sku)
    },
    setSearch(context, search) {
      context.commit("SET_SEARCH", search);
    },
    // fetch all sku's for searchbar
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_LOADING", true);
        //custom filters
        let filterlist = EnvProvider.value("STORE_ENTITY_FILTERS");
        let filters = filterlist.split(",");
        let i;
        let urlFilters = "";
        for (i = 0; i < filters.length; i++) {
          urlFilters += '&' + filters[i] + "=" + context.state.search;
        }
        axios({
          method: "get",
          url:
            EnvProvider.value("STORE_ENTITY") +
            "skus:quick-search?page=" +
            (context.state.itemsPage > 1 ? context.state.itemsPage : "1") +
            "&itemsPerPage=" +
            context.state.itemsPerPage +
            "&sku=" +
            context.state.search +
            urlFilters,
          headers: {
            Accept: "application/json",
            'x-entity-user': window.sessionStorage.getItem("credentials") ?? EnvProvider.value("GUEST_USER")+":"+EnvProvider.value("GUEST_PASSWORD"),
            'x-entity-user-barcode': window.sessionStorage.getItem("barcode")
          }
        }).then(
          response => {
            context.commit("SET_ITEMS", response.data);
            context.commit("SET_LOADING", false);
            context.commit("SET_CONNECTION", true);
          },
          err => {
            context.commit("SET_LOADING", false);
            context.commit("SET_CONNECTION", false);
            console.log(err);
          }
        );
      }
    },
    fetchSku(context, sku) {
        axios({
          method: "get",
          url:
              EnvProvider.value("STORE_ENTITY") + "skus/" + sku,
          headers: {
            Accept: "application/json",
            'x-entity-user': window.sessionStorage.getItem("credentials") ?? EnvProvider.value("GUEST_USER")+":"+EnvProvider.value("GUEST_PASSWORD"),
            'x-entity-user-barcode': window.sessionStorage.getItem("barcode")
          }
        }).then(
            response => {
              context.commit("SET_SKU", response.data);
              context.commit("SET_CONNECTION", true);
            },
            err => {
              context.commit("SET_CONNECTION", false);
              console.log(err);
            }
        );
    },
    searchItems(context) {
      context.dispatch("fetchItems", true);
      context.state.showItems = true;
    },
    resetOptions(context) {
      context.state.showItems = false;
    }
  }
};

export default products;
